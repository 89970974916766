// container
.container {
	@media (min-width: 1280px) {
		max-width: 1210px;
	}

	&.container-small {
		@include media-breakpoint-up(sm) {
			max-width: 540px;
		}
	}

	&.container-medium {
		@include media-breakpoint-up(md) {
			max-width: 720px;
		}
	}

	&.container-large {
		@include media-breakpoint-up(lg) {
			max-width: 960px;
		}
	}

	&.container-intro {
		@include media-breakpoint-up(md) {
			max-width: 720px;
		}

		margin-bottom: 30px;
		text-align: center;
	}

	&.container-outro {
		@include media-breakpoint-up(md) {
			max-width: 720px;
		}

		margin-top: 30px;
		text-align: center;
	}
}

// paragraph
p {
	min-height: 1rem;
}

// img & iframe
img {
	max-width: 100%;
	height: auto;
}

iframe {
	max-width: 100%;
}

// transition
a,
.btn,
.owl-btn,
.card-btn {
	transition: 0.5s;
}

// form
.form {
	padding: 15px;

	@include media-breakpoint-up(xl) {
		padding: 30px;
	}

	border: 1px solid #ced4da;
	border-radius: 0.25rem;
}

// image-collection
.image-collection {
	&.owl-carousel {
		.item {
			picture {
				width: 100%;
				height: 100%;

				img {
					height: 100%;
					object-fit: cover;
				}
			}

			.owl-caption-holder {
				z-index: 100;
				position: absolute;
				top: 0;
				left: 0;
				right: 0;
				bottom: 0;
				display: flex;
				align-items: center;
				justify-content: center;
			}
		}

		// owl-nav
		.owl-nav {
			button {
				font-size: 24px;
				text-shadow: none;

				@include media-breakpoint-between(sm, lg) {
					font-size: 36px;
				}

				@include media-breakpoint-up(xl) {
					width: 50px;
					font-size: 48px;

					&.owl-prev {
						left: 15px;
					}

					&.owl-next {
						right: 15px;
					}
				}
			}
		}
	}
}

// gallery
.gallery,
.gallery-horizontal {
	.gallery-item {
		.gallery-link {
			border-radius: 12px;
			box-shadow: $shadow;
		}
	}

	.gallery-label {
		top: 15px;
		left: 0;
		padding: 5px 15px;
		background-color: $primary;
		border-radius: 0 99px 99px 0;
		color: $white;
	}
}

// default-card
.default-card {
	border-radius: 12px;
	box-shadow: 0 4px 20px rgba($black, 0.3);
	border: none;

	.card-image {
		&::after {
			content: '';
			z-index: 10;
			position: absolute;
			left: 0;
			right: 0;
			bottom: -1px;
			height: 14px;
			background: url('/images/card-shape.svg');
		}

		.card-image-caption {
			top: 15px;
			right: 0;
			left: auto;
			bottom: auto;
			background-color: $primary;
			border-radius: 99px 0 0 99px;
		}
	}

	.card-body {
		@include media-breakpoint-up(xl) {
			padding: 15px 30px 30px 30px;
		}

		.card-caption {
			.card-title {
				@include font-size($h4-font-size);
			}

			.card-subtitle {
				margin-top: 0;
				color: $red;
				font-size: 16px;
				font-weight: 700;
				font-family: $font-family-primary;
			}
		}

		.card-buttons {
			margin-top: 30px;
		}
	}
}
