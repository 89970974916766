// footer
.footer {
	margin-top: 6vh;

	// footer-partners
	.footer-partners {
		margin-bottom: 30px;

		ul {
			display: flex;
			flex-wrap: wrap;
			align-items: center;
			justify-content: flex-end;
			margin: 0 -15px;
			padding: 0;
			list-style: none;

			@include media-breakpoint-down(sm) {
				justify-content: center;
			}

			li {
				margin: 15px;
			}
		}
	}

	// footer-outro
	.footer-outro {
		position: relative;
		margin-top: 15px;
		padding: 8vh 0 30px 0;
		background: $green-dark;
		color: $white;
		text-align: center;

		&::before {
			content: '';
			position: absolute;
			top: -18px;
			left: 0;
			right: 0;
			height: 30px;
			background: url('/images/shape-green-neon.svg') no-repeat center;
		}

		h1,
		h2,
		h3,
		h4,
		h5,
		h6 {
			color: $white;
		}

		a {
			color: $green-neon;

			&:hover {
				color: $white;
			}
		}

		// footer-link
		.footer-link {
			margin-bottom: 1rem;
			color: $green-neon;
			font-size: 20px;
			font-weight: 500;
			font-family: $font-family-secondary;

			ul {
				flex-direction: column;
				justify-content: center;

				li {
					a {
						text-decoration: none;
					}
				}
			}
		}

		// footer-logolink
		.footer-logolink {
			margin-top: 1rem;

			ul {
				justify-content: center;

				li {
					margin: 0 10px;
					font-size: 32px;

					a {
						display: block;
					}
				}
			}
		}

		// footer-text-copyright
		.footer-text-copyright {
			margin-top: 8vh;

			ul {
				display: flex;
				flex-wrap: wrap;
				align-items: center;
				justify-content: center;
				margin: 0;
				padding: 0;
				list-style: none;

				@include media-breakpoint-down(sm) {
					flex-direction: column;
				}

				li {
					color: $green-neon;
					font-size: 14px;

					@include media-breakpoint-up(md) {
						margin: 0 15px;
					}

					a {
						text-decoration: none;
					}
				}
			}
		}
	}
}
