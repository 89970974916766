// mini-sab
.mini-sab {
	.container {
		position: relative;
		z-index: 100;
		display: flex;
		justify-content: center;

		.container-holder {
			margin-top: -35px;
		}
	}
}
