// eyecatcher
.eyecatcher,
.eyecatcher .owl-carousel .item {
	height: 50vh;
	min-height: 300px;
	max-height: 540px;
}

.eyecatcher.large,
.eyecatcher.large .owl-carousel .item {
	height: 75vh;
	min-height: 350px;
	max-height: 1080px;

	@include media-breakpoint-up(sm) {
		min-height: 500px;
		height: calc(80vh - 120px);
	}
}

// eyecatcher
.eyecatcher {
	position: relative;

	&::after {
		content: '';
		z-index: 1;
		position: absolute;
		top: calc(100% - 18px);
		left: 0;
		right: 0;
		height: 30px;
		background: url('/images/shape-green-dark.svg') no-repeat center;
	}

	// vimeo-video
	.vimeo-video {
		iframe {
			position: absolute;
			top: 50%;
			left: 50%;
			width: 100vw;
			height: 100vh;
			max-width: none;
			transform: translate(-50%, -50%);

			@media (min-aspect-ratio: 16/9) {
				height: 56.25vw;
			}

			@media (max-aspect-ratio: 16/9) {
				width: 177.78vh;
			}
		}
	}

	// owl-carousel
	.owl-carousel {
		.item {
			.owl-caption-holder {
				z-index: 100;

				.owl-container {
					margin-top: auto;

					.owl-caption {
						margin-bottom: 100px;
						color: $white;
						text-shadow: none;

						.owl-title {
							font-size: 36px;
							font-weight: 500;
							line-height: 1;

							@include media-breakpoint-between(sm, lg) {
								font-size: 48px;
							}

							@include media-breakpoint-up(xl) {
								font-size: 96px;
							}
						}
					}
				}
			}
		}

		// owl-dots
		.owl-dots {
			display: none;
		}
	}

	&:not(.large) {

		// owl-nav
		.owl-nav {
			display: none;
		}
	}
}
