// bg-*
// =========================================================================
section {

	&.bg-light,
	&.bg-dark {
		margin: 0;
		padding: 6vh 0;
	}

	&.bg-pattern {
		position: relative;
		margin: 0;
		padding: 6vh 0;
		background: url('/images/pattern-bg.svg') no-repeat center 30px $green-neon;

		&::before {
			content: '';
			position: absolute;
			top: -18px;
			left: 0;
			right: 0;
			height: 30px;
			background: url('/images/shape-green-neon.svg') no-repeat center;
		}

		&::after {
			content: '';
			position: absolute;
			left: 0;
			right: 0;
			bottom: -12px;
			height: 30px;
			background: url('/images/shape-green-neon.svg') no-repeat center;
		}
	}
}

.lead-section+.content-section,
.lead-section+.bundle-detail-section,
.lead-section+.bundle-overview-section {
	margin-top: calc(-6vh + 30px);
}

.header + .mini-sab {
	 .container {
		 .container-holder {
  			margin-top: 30px;
		 }
	 }
}

// lead-section
// =========================================================================
.lead-section {
	margin: 6vh 0;

	h1 {
		margin-bottom: 1.5rem;
	}

	.container-one-column {
		max-width: 770px;

		.container-holder {
			text-align: center;
		}
	}

	// bundles
	.info-nav {
		@extend .d-flex;
		@extend .flex-wrap;
		@extend .align-items-center;
		@extend .justify-content-between;
		margin-bottom: 30px;
	}

	.info {

		// accommodation
		.properties {
			@extend .d-flex;
			@extend .flex-wrap;
			@extend .justify-content-center;
			@extend .list-unstyled;

			li {
				margin: 0 15px;
			}

			.icon {
				margin-right: 5px;
			}
		}

		// assortiment
		.former-price {
			font-size: $font-size-sm;
			text-decoration: line-through;
		}

		.discount-price {
			font-size: $font-size-sm;

			.amount {
				font-size: $font-size-lg;
			}

			margin-bottom: 1rem;
		}

		.price {
			margin-bottom: 1rem;

			.amount {
				font-size: $font-size-lg;
			}
		}
	}
}

&.accommodation-detail {
	.lead-section {
		margin-bottom: 6vh;
	}
}

// content-section
// =========================================================================
.content-section {
	margin: 6vh 0;
}

// collection-section
// =========================================================================
.collection-section {
	margin: 6vh 0;

	// collection
	.collection {
		.card {
			@extend .default-card !optional;
		}

		&.custom {
			.card {
				.card-body {
					.card-caption {
						.card-title {
							@include font-size($h3-font-size);
						}
					}
				}
			}
		}
	}
}

// service-section
// =========================================================================
.service-section {
	position: relative;
	padding: 6vh 0;
	background: $cream;

	&::before {
		content: '';
		position: absolute;
		top: -18px;
		left: 0;
		right: 0;
		height: 30px;
		background: url('/images/shape-cream.svg') no-repeat center;
	}

	&::after {
		content: '';
		position: absolute;
		left: 0;
		right: 0;
		bottom: -12px;
		height: 30px;
		background: url('/images/shape-cream.svg') no-repeat center;
	}

	// collection
	.collection {
		&.grid {
			.grid-items {
				.item {
					@include media-breakpoint-up(lg) {
						@include make-col(6);
					}

					@include media-breakpoint-up(xl) {
						@include make-col(6);
					}
				}
			}
		}

		.card {
			box-shadow: 0 4px 20px rgba($black, 0.3);
			border: none;
			border-radius: 12px;

			.card-img-overlay {
				display: flex;
				top: auto;
				min-height: 50%;
				border-radius: 0;
				background: linear-gradient(0deg, rgba($black, 0.6) 0%, rgba($black, 0) 100%);

				@include media-breakpoint-up(xl) {
					padding: 30px;
				}

				.card-caption {
					margin-top: auto;
					text-shadow: none;

					.card-subtitle {
						margin: 0;
						font-size: 16px;
						font-weight: 700;
						font-family: $font-family-primary;
					}

					.card-description {
						display: none
					}
				}
			}
		}
	}
}

// banner-section
// =========================================================================
.banner-section {
	position: relative;
	background-color: $cream;
	overflow: hidden;

	&::before {
		content: '';
		z-index: 100;
		position: absolute;
		top: -18px;
		left: 0;
		right: 0;
		height: 30px;
		background: url('/images/shape-green-neon.svg') no-repeat center;
	}

	.owl-carousel {
		min-height: auto;

		.item {
			flex-direction: column;
			min-height: auto;
			padding: 0;

			picture {
				height: 75vh;
				min-height: 300px;
				max-height: 720px;
			}

			.owl-caption-holder {
				position: relative;
				background-color: $cream;

				&::before {
					content: '';
					position: absolute;
					top: -18px;
					left: 0;
					right: 0;
					height: 30px;
					background: url('/images/shape-green-neon.svg') no-repeat center;
				}

				.owl-container {
					max-width: 750px;
					margin-top: -110px;
					padding-bottom: 24px;

					.owl-caption {
						position: relative;
						min-height: 190px;
						padding: 30px 30px 30px 110px;
						background: $white;
						border-radius: 12px;
						box-shadow: 0 4px 20px rgba($black, 0.3);
						color: $green-dark;
						text-shadow: none;

						@include media-breakpoint-up(md) {
							padding: 30px 30px 30px 150px;
						}

						&::before {
							content: '';
							position: absolute;
							top: 40px;
							left: 30px;
							width: 49px;
							height: 110px;
							background-image: url('/images/visual-lighthouse.svg');

							@include media-breakpoint-up(md) {
								left: 50px;
							}
						}

						.owl-title {
							font-size: 24px;
						}

						.owl-subtitle {
							color: $red;
							font-size: 16px;
							font-weight: 700;
							font-family: $font-family-primary;
						}
					}
				}
			}
		}

		// owl-dots
		.owl-dots {
			display: none;
		}
	}
}

// faq-section
// =========================================================================
.faq-section {
	margin: 6vh 0;

	.container {
		@include media-breakpoint-up(md) {
			max-width: 750px;
		}
	}

	// faq-overview
	.faq-overview {
		margin: 20px 0;

		.faq-category {
			margin-top: 0;

			.faq-items {
				border-top: 1px solid $blue;

				.faq-item {
					.faq-question {
						margin-bottom: 0;
						padding: 10px 20px 10px 0;
						border: none;
						border-bottom: 1px solid $blue;

						h3 {
							color: $blue;
							font-size: 20px;
						}

						.faq-icon {
							left: auto;
							right: 0;
							width: auto;
							line-height: 45px;

							i {
								&::before {
									content: '\f067';
								}
							}
						}
					}

					.faq-answer {
						margin-bottom: 0;
						padding: 10px;
						border: none;

						p:last-of-type {
							margin-bottom: 0;
						}
					}

					&.active {
						.faq-question {
							.faq-icon {
								i {
									&::before {
										content: '\f068';
									}
								}
							}
						}
					}
				}
			}
		}
	}
}


// bundle-overview-section
// =========================================================================
.bundle-overview-section {
	margin: 6vh 0;

	// collection
	.collection {
		.card {
			@extend .default-card !optional;
		}
	}
}

// bundle-detail-section
// =========================================================================
.bundle-detail-section {
	margin: 6vh 0;
}

// gallery-section
// =========================================================================
.gallery-section {
	margin-top: calc(-6vh + 30px);
	margin-bottom: calc(-6vh + 30px);
}

// booking-section
// =========================================================================
.booking-section {
	margin: 6vh 0;

	h2 {
		margin-bottom: 30px;
	}
}

// contact-section
// =========================================================================
.contact-section {
	margin: 6vh 0;
}
