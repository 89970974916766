// header
.header {
	z-index: 998;
	position: sticky;
	top: 0;
	left: 0;
	right: 0;
	background: $cream;
	transition: 0.5s;

	@include media-breakpoint-up(xl) {
		padding: 15px 15px 0 15px;
	}

	@include media-breakpoint-down(xs) {
		padding: 15px 0 20px 0;
	}

	&::after {
		content: '';
		z-index: -1;
		position: absolute;
		top: calc(100% - 18px);
		left: 0;
		right: 0;
		height: 30px;
		background: url('/images/shape-green-dark.svg') no-repeat center;
	}

	.container,
	.container-fluid {
		.container-holder {
			>.column {
				align-items: center;

				@include media-breakpoint-down(lg) {
					flex-flow: row wrap;
				}

				@extend .navbar-expand-xl;
			}
		}
	}

	// menu-toggle
	.menu-toggle {
		.navbar-toggler {
			width: 40px;
			height: 40px;
			padding: 0;
			outline: none;
			border: none;
			border-radius: 100%;
			background: $white;
			color: $red;
			font-size: 24px;
			transition: 0.5s;

			i {
				line-height: 40px;
				transition: transform 0.5s;
			}

			&[aria-expanded="true"] {
				i {
					transform: rotate(180deg);

					&::before {
						content: "\f00d";
					}
				}
			}

			&:hover {
				background: $red;
				color: $white;
			}
		}
	}

	// logo
	.logo {
		width: 235px;
		margin: 0 30px -58px 10px;
		transition: 0.5s;

		@include media-breakpoint-down(xs) {
			position: absolute;
			top: 75px;
			left: 0;
			right: 0;
			width: 200px;
			margin: 0 auto;
		}

		@include media-breakpoint-down(md) {
			width: 225px;
		}

		@include media-breakpoint-between(sm, lg) {
			margin-top: 15px;
		}

		a {
			display: block;

			img {
				@extend .w-100;
			}
		}
	}

	// menu-default
	.menu-default {
		@include media-breakpoint-down(lg) {
			order: 5;

			.navbar-nav {
				@include media-breakpoint-down(xs) {
					margin-top: 15px;
				}

				>.nav-item {
					>.nav-link {
						display: flex;
						align-items: center;
						color: $green-dark;
						font-size: 16px;
						font-weight: 700;

						&:hover {
							color: $red;
						}
					}

					&.active {
						>.nav-link {
							color: $red;
						}
					}
				}

				.nav-item {
					.dropdown-menu {
						position: static !important;
						transform: none !important;
						margin: 0 0 0 15px;
						padding: 0;
						border: none;
						border-radius: 6px;
						box-shadow: none;
						background: transparent;

						.nav-item {
							font-size: 15px;

							.nav-link {
								color: $green-dark;

								&:hover {
									color: $red;
								}
							}

							&:first-of-type {
								.nav-link {
									padding-top: 0;
								}
							}

							&.active {
								>.nav-link {
									color: $red;
								}
							}
						}
					}
				}
			}
		}

		@include media-breakpoint-up(xl) {
			margin-bottom: 0.5rem;

			.navbar-nav {
				>.nav-item {
					margin-right: 15px;
					font-size: 16px;
					font-weight: 500;

					>.nav-link {
						color: $green-dark;

						&:hover {
							color: $red;
						}
					}

					&.active {
						>.nav-link {
							color: $red;
						}
					}
				}

				.nav-item {
					.dropdown-menu {
						padding: 0.5rem;
						//background-color: $cream;
						//border-color: $cream;

						&::before,
						&::after {
							//border-bottom-color: $cream;
						}

						.nav-item {
							font-size: 15px;
							font-weight: 400;
							line-height: 1.2;
							white-space: nowrap;

							.nav-link {
								color: $green-dark;

								&:hover {
									color: $red;
								}
							}

							&.active {
								.nav-link {
									color: $red;
								}
							}
						}
					}
				}
			}
		}
	}

	// menu-extra
	.menu-extra {
		@include media-breakpoint-down(lg) {
			order: 6;

			.navbar-nav {
				@include media-breakpoint-between(sm, lg) {
					margin-bottom: 15px;
				}

				>.nav-item {
					>.nav-link {
						display: flex;
						align-items: center;
						color: $green-dark;
						font-size: 16px;
						font-weight: 700;

						&:hover {
							color: $red;
						}
					}

					&.active {
						>.nav-link {
							color: $red;
						}
					}
				}

				.nav-item {
					.dropdown-menu {
						position: static !important;
						transform: none !important;
						margin: 0 0 0 15px;
						padding: 0;
						border: none;
						border-radius: 6px;
						box-shadow: none;
						background: transparent;

						.nav-item {
							font-size: 15px;

							.nav-link {
								color: $green-dark;

								&:hover {
									color: $red;
								}
							}

							&:first-of-type {
								.nav-link {
									padding-top: 0;
								}
							}

							&.active {
								>.nav-link {
									color: $red;
								}
							}
						}
					}
				}
			}
		}

		@include media-breakpoint-up(xl) {
			position: absolute;
			top: -0.5rem;
			right: 15px;

			.navbar-nav {
				>.nav-item {
					margin-left: 5px;
					font-size: 14px;

					>.nav-link {
						color: $green-dark;

						&:hover {
							color: $red;
						}
					}

					&.active {
						>.nav-link {
							color: $red;
						}
					}
				}

				.nav-item {
					.dropdown-menu {
						padding: 0.5rem;
						//background-color: $cream;
						//border-color: $cream;

						&::before,
						&::after {
							//border-bottom-color: $cream;
						}

						.nav-item {
							font-size: 14px;
							font-weight: 400;
							line-height: 1.2;
							white-space: nowrap;

							.nav-link {
								color: $green-dark;

								&:hover {
									color: $red;
								}
							}

							&.active {
								.nav-link {
									color: $red;
								}
							}
						}
					}
				}
			}
		}
	}

	// site-switcher
	.site-switcher {
		margin-left: auto;
	}

	// book-button
	div.book-button {
		margin-left: 20px;

		a {
			@extend .btn;
			@extend .btn-primary;
			padding-left: 1.5rem !important;
			padding-right: 1.5rem !important;
			font-weight: 700 !important;
			font-family: $font-family-primary !important;

			@include media-breakpoint-down(sm) {
				padding: 2px 1rem !important;
				font-size: 13px !important;
			}
		}
	}
}

&.w3-booking-widget-fullscreen-active {
	.header {
		z-index: 100;
	}
}

&.navbar-collapse-active,
&.sticky {
	.header {
		.logo {
			@include media-breakpoint-between(sm, lg) {
				width: 100px;
				margin-bottom: 0;
			}

			@include media-breakpoint-down(xs) {
				opacity: 0;
				visibility: hidden;
			}
		}
	}
}
